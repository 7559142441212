<template>
    <v-tab-item :value="_account">
        <v-row>
            <template v-for="item in items">
                <v-col cols="6" md="4" lg="3" :key="item._id">
                    <v-hover v-slot="{ hover }">
                        <v-responsive :aspect-ratio="1 / 1">
                            <v-card flat tile :href="item.href" target="_blank">
                                <v-img :src="item?.srcs?.[0]" :aspect-ratio="1 / 1">
                                    <v-fade-transition>
                                        <v-overlay v-show="hover" absolute z-index="0" color="black" opacity="0.6">
                                            <v-icon size="100" color="rgba(255,255,255,0.2)">mdi-instagram</v-icon>
                                        </v-overlay>
                                    </v-fade-transition>
                                    <v-fade-transition>
                                        <v-overlay v-show="hover" absolute color="transparent">
                                            <v-card-text>
                                                <!-- <div class="sns-content-item-text">{{ item?.text }}</div> -->
                                            </v-card-text>
                                        </v-overlay>
                                    </v-fade-transition>
                                    <v-fade-transition>
                                        <v-btn v-show="hover" absolute top right x-small icon color="white" class="pa-0">
                                            <v-icon v-if="item?.kind == 'image'" size="24">mdi-image</v-icon>
                                            <v-icon v-if="item?.kind == 'slide'" size="24">mdi-view-carousel</v-icon>
                                            <v-icon v-if="item?.kind == 'video'" size="24">mdi-play-box</v-icon>
                                        </v-btn>
                                    </v-fade-transition>
                                    <v-fade-transition>
                                        <v-btn v-show="hover" absolute bottom left x-small text color="white" class="pa-0">
                                            <span> <v-icon small>mdi-heart</v-icon> {{ item?.meta?.likes || 0 }} </span>
                                        </v-btn>
                                    </v-fade-transition>
                                    <v-fade-transition>
                                        <v-btn v-show="hover" absolute bottom right x-small text color="white" class="pa-0">
                                            <span> {{ item?.date?.toDateTime?.() }} </span>
                                        </v-btn>
                                    </v-fade-transition>
                                </v-img>
                            </v-card>
                        </v-responsive>
                    </v-hover>
                </v-col>
            </template>
        </v-row>
        <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-40" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
    </v-tab-item>
</template>

<script>
import api from "@/api";

export default {
    props: {
        tab: { type: String, default: null },
        _account: { type: String, default: null },
    },
    data: () => ({
        contents: [],

        limit: 12,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.contents];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            let { _account } = this;
            query._account = _account;
            if (!_account) delete query._account;

            return { ...query };
        },
    },
    mounted() {
        if (this.tab == this._account) this.search();
    },
    watch: {
        tab() {
            if (this.tab == this._account) this.init();
        },
        params() {
            if (this.tab == this._account) this.search();
        },
    },
    methods: {
        async init() {
            if (this.params.page) this.$router.replace({ query: {} });
            this.search();
        },

        async search() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { skip, limit, params, _account } = this;
                var { summary, contents } = await api.v1.sns.accounts.contents.gets({
                    _account,
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.contents = contents;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
.sns-content-item-text {
    opacity: 0.8;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    word-wrap: normal;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
</style>
