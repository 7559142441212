var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "global-network-list"
  }, [_c('div', {
    staticClass: "tab-wrap mb-20 mb-lg-30"
  }, [_c('v-tabs-block', _vm._l(_vm.branchGroups, function (_ref) {
    var text = _ref.text,
      value = _ref.value;
    return _c('v-tab', {
      key: value
    }, [_c('v-btn', {
      attrs: {
        "outlined": "",
        "block": "",
        "color": "grey-e6",
        "to": `/global/network/${value}`
      }
    }, [_vm._v(_vm._s(text))])], 1);
  }), 1)], 1), _c('v-row', {
    staticClass: "mb-20 mb-lg-30",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Country Name",
      "clearable": "",
      "outlined": "",
      "hide-details": "",
      "prepend-inner-icon": "mdi-magnify"
    },
    model: {
      value: _vm.filter.countryName,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "countryName", $$v);
      },
      expression: "filter.countryName"
    }
  })], 1)], 1), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab,
      "touchless": ""
    }
  }, [_vm._l(_vm.items, function (_ref2) {
    var value = _ref2.value,
      countries = _ref2.countries;
    return [_c('v-tab-item', _vm._b({
      key: `tab-item--${value}`
    }, 'v-tab-item', {
      value
    }, false), _vm._l(countries, function (country, index) {
      return _c('global-network-item', _vm._b({
        key: country.name
      }, 'global-network-item', {
        country,
        index
      }, false));
    }), 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }