var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "disableScrollTop": ""
    },
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          ref: "page-top",
          attrs: {
            "title": "Global",
            "tabActive": "News"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('sns-navigation', {
    model: {
      value: _vm.accounts,
      callback: function ($$v) {
        _vm.accounts = $$v;
      },
      expression: "accounts"
    }
  }), _c('v-divider'), _c('v-tabs-items', {
    staticClass: "mt-40",
    attrs: {
      "value": _vm.tab,
      "touchless": ""
    }
  }, [_c('sns-content-list', _vm._b({}, 'sns-content-list', {
    tab: _vm.tab
  }, false)), _vm._l(_vm.tabs, function (_account) {
    return _c('sns-content-list', _vm._b({
      key: _account
    }, 'sns-content-list', {
      tab: _vm.tab,
      _account
    }, false));
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }