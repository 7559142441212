var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm._account
    }
  }, [_c('v-row', [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item._id,
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var _item$srcs, _item$meta, _item$date, _item$date$toDateTime;
          var hover = _ref.hover;
          return [_c('v-responsive', {
            attrs: {
              "aspect-ratio": 1 / 1
            }
          }, [_c('v-card', {
            attrs: {
              "flat": "",
              "tile": "",
              "href": item.href,
              "target": "_blank"
            }
          }, [_c('v-img', {
            attrs: {
              "src": item === null || item === void 0 ? void 0 : (_item$srcs = item.srcs) === null || _item$srcs === void 0 ? void 0 : _item$srcs[0],
              "aspect-ratio": 1 / 1
            }
          }, [_c('v-fade-transition', [_c('v-overlay', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: hover,
              expression: "hover"
            }],
            attrs: {
              "absolute": "",
              "z-index": "0",
              "color": "black",
              "opacity": "0.6"
            }
          }, [_c('v-icon', {
            attrs: {
              "size": "100",
              "color": "rgba(255,255,255,0.2)"
            }
          }, [_vm._v("mdi-instagram")])], 1)], 1), _c('v-fade-transition', [_c('v-overlay', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: hover,
              expression: "hover"
            }],
            attrs: {
              "absolute": "",
              "color": "transparent"
            }
          }, [_c('v-card-text')], 1)], 1), _c('v-fade-transition', [_c('v-btn', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: hover,
              expression: "hover"
            }],
            staticClass: "pa-0",
            attrs: {
              "absolute": "",
              "top": "",
              "right": "",
              "x-small": "",
              "icon": "",
              "color": "white"
            }
          }, [(item === null || item === void 0 ? void 0 : item.kind) == 'image' ? _c('v-icon', {
            attrs: {
              "size": "24"
            }
          }, [_vm._v("mdi-image")]) : _vm._e(), (item === null || item === void 0 ? void 0 : item.kind) == 'slide' ? _c('v-icon', {
            attrs: {
              "size": "24"
            }
          }, [_vm._v("mdi-view-carousel")]) : _vm._e(), (item === null || item === void 0 ? void 0 : item.kind) == 'video' ? _c('v-icon', {
            attrs: {
              "size": "24"
            }
          }, [_vm._v("mdi-play-box")]) : _vm._e()], 1)], 1), _c('v-fade-transition', [_c('v-btn', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: hover,
              expression: "hover"
            }],
            staticClass: "pa-0",
            attrs: {
              "absolute": "",
              "bottom": "",
              "left": "",
              "x-small": "",
              "text": "",
              "color": "white"
            }
          }, [_c('span', [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-heart")]), _vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : _item$meta.likes) || 0) + " ")], 1)])], 1), _c('v-fade-transition', [_c('v-btn', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: hover,
              expression: "hover"
            }],
            staticClass: "pa-0",
            attrs: {
              "absolute": "",
              "bottom": "",
              "right": "",
              "x-small": "",
              "text": "",
              "color": "white"
            }
          }, [_c('span', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$date = item.date) === null || _item$date === void 0 ? void 0 : (_item$date$toDateTime = _item$date.toDateTime) === null || _item$date$toDateTime === void 0 ? void 0 : _item$date$toDateTime.call(_item$date)) + " ")])])], 1)], 1)], 1)], 1)];
        }
      }], null, true)
    })], 1)];
  })], 2), _c('v-pagination', {
    staticClass: "mt-40",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }