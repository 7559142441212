var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "centered": "",
      "center-active": ""
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-tab', {
      key: item._id,
      attrs: {
        "to": item.to,
        "exact": ""
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }