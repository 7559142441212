<template>
    <v-tabs centered center-active>
        <v-tab v-for="item in items" :key="item._id" :to="item.to" exact>{{ item.name }}</v-tab>
    </v-tabs>
</template>

<script>
import api from "@/api";
export default {
    props: {
        baseUri: { type: String, default: "/global/sns" },
    },
    data: () => ({
        accounts: [],

        loading: false,
    }),
    computed: {
        items() {
            const query = { ...this.$route.query };

            return [{ name: "All", to: { path: this.baseUri, query } }].concat(this.accounts.map((item) => ({ ...item, to: { path: `${this.baseUri}/${item._id}`, query } })));
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        accounts() {
            this.$emit("input", this.accounts);
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            this.loading = true;

            try {
                const { accounts } = await api.v1.sns.accounts.gets();
                this.accounts = accounts;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>