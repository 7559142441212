<template>
    <div class="global-network-list">
        <div class="tab-wrap mb-20 mb-lg-30">
            <v-tabs-block>
                <v-tab v-for="{ text, value } in branchGroups" :key="value">
                    <v-btn outlined block color="grey-e6" :to="`/global/network/${value}`">{{ text }}</v-btn>
                </v-tab>
            </v-tabs-block>
        </div>

        <v-row justify="end" class="mb-20 mb-lg-30">
            <v-col cols="auto">
                <v-text-field v-model="filter.countryName" placeholder="Country Name" clearable outlined hide-details prepend-inner-icon="mdi-magnify" />
            </v-col>
        </v-row>

        <v-tabs-items :value="tab" touchless>
            <template v-for="{ value, countries } in items">
                <v-tab-item v-bind="{ value }" :key="`tab-item--${value}`">
                    <global-network-item v-for="(country, index) in countries" :key="country.name" v-bind="{ country, index }" />
                </v-tab-item>
            </template>
        </v-tabs-items>
    </div>
</template>

<script>
import api from "@/api";
import { BRANCH_GROUPS, COMPANY_WEBSITE_TYPES } from "@/assets/variables";

import VTabsBlock from "@/components/client/dumb/v-tabs-block.vue";
import GlobalNetworkItem from "./global-network-item.vue";

const branchGroups = Object.values(BRANCH_GROUPS);
export default {
    components: {
        VTabsBlock,
        GlobalNetworkItem,
    },
    data: () => ({
        branches: [],
        branchGroups,

        filter: {
            countryName: null,
        },

        loading: false,
    }),
    computed: {
        tab() {
            return this.$route.params.tab || branchGroups[0].value;
        },
        items() {
            return branchGroups.map((group) => ({
                ...group,
                countries: this.branches
                    .filter(({ company, zone }) => {
                        const isSameGroup = company?.group == group.value;
                        if (this.filter.countryName) {
                            const isSameCountry = zone?.countryName?.toLowerCase?.()?.includes?.(this.filter.countryName?.toLowerCase?.());
                            return isSameGroup && isSameCountry;
                        } else {
                            return isSameGroup;
                        }
                    })
                    .reduce((countries, branch) => {
                        let countryIndex = countries.findIndex(({ name }) => name == branch?.zone?.countryName);
                        if (0 <= countryIndex) {
                            let country = countries[countryIndex];
                            countries.splice(countryIndex, 1, { ...country, branches: (country?.branches || []).concat(branch) });
                        } else {
                            let country = {
                                name: branch.zone?.countryName,
                                flag: branch.zone?.countryFlag,
                                branches: [branch],
                            };
                            countries.push(country);
                        }
                        return countries;
                    }, []),
            }));
        },
    },
    mounted() {
        this.setRoute().then(this.init);
    },
    methods: {
        async setRoute() {
            const path = `/global/network/${this.tab}`;
            if (this.$route.path != path) this.$router.replace({ path });
        },
        async init() {
            this.loading = true;
            try {
                const { branches = [] } = await api.v1.branches.gets();
                this.branches = branches
                    .sort((a, b) => {
                        if (a?.zone?.countryIndex != b?.zone?.countryIndex) {
                            return a?.zone?.countryIndex < b?.zone?.countryIndex ? -1 : 1;
                        } else return a?.zone?.countryName?.localeCompare?.(b?.zone?.countryName);
                    })
                    .map((item) => ({
                        ...item,
                        company: {
                            ...(item?.company || {}),
                            websites: ((item?.company || {})?.websites || []).map((website) => ({
                                ...website,
                                icon: COMPANY_WEBSITE_TYPES[website?.type]?.icon,
                            })),
                        },
                    }));
            } finally {
                this.loading = false;
            }

            var globalNetworkList = document.querySelector(".global-network-list");
            var tab = document.querySelectorAll(".v-tab");
            var globalNetworkListTop = window.pageYOffset + globalNetworkList.getBoundingClientRect().top;
            var mobileWidth = 1200;
            var headerHeight = 60;
            if (window.innerWidth > mobileWidth) {
                headerHeight = 100;
            }
            var scrollTop = globalNetworkListTop - headerHeight - 10;

            setTimeout(() => {
                tab.forEach((element) => {
                    element.addEventListener("click", function () {
                        window.scrollTo(0, scrollTop);
                    });
                });
            }, 100);
        },
    },
};
</script>
