<template>
    <client-page disableScrollTop>
        <template v-slot:subHead>
            <sub-visual title="Global" tabActive="News" ref="page-top" />
        </template>

        <v-container>
            <sns-navigation v-model="accounts" />
            <v-divider />
            <v-tabs-items :value="tab" class="mt-40" touchless>
                <sns-content-list v-bind="{ tab }" />
                <sns-content-list v-for="_account in tabs" :key="_account" v-bind="{ tab, _account }" />
            </v-tabs-items>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SnsNavigation from "@/components/client/global-sns/sns-navigation.vue";
import SnsContentList from "@/components/client/global-sns/sns-content-list.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SnsNavigation,
        SnsContentList,
    },
    props: {
        tab: { type: String, default: null },
    },
    data: () => ({
        accounts: [],
    }),
    computed: {
        tabs() {
            return this.accounts.map(({ _id }) => _id);
        },
    },
    watch: {
        $route() {
            this.$refs["page-top"]?.$el?.scrollIntoView?.({ behavior: "smooth" });
        },
    },
};
</script>
