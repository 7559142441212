var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "disableScrollTop": ""
    },
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "Global",
            "tabActive": "Network",
            "src": "/images/sub/sv-story-global-network.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('global-network-showcase', {
    staticClass: "mb-40 mb-md-60 mb-lg-80"
  }), _c('global-network-list')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }