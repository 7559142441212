var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "item",
    class: {
      'mt-36 mt-md-46 mt-lg-56': _vm.index != 0
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--hyphen mb-16 mb-md-30"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v(_vm._s(_vm.country.name))])]), _c('v-expansion-panels', {
    staticClass: "panels-basic",
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.country.branches, function (branch) {
    var _branch$company, _branch$company2, _branch$company3, _branch$company4, _branch$company5, _branch$company6, _branch$company7;
    return [_c('v-expansion-panel', {
      key: branch._id
    }, [_c('v-expansion-panel-header', [_c('div', [_c('div', {
      staticClass: "font-spoqa font-size-14 font-size-md-16 font-weight-medium secondary--text mb-10 mb-md-14"
    }, [_vm._v(_vm._s(branch === null || branch === void 0 ? void 0 : (_branch$company = branch.company) === null || _branch$company === void 0 ? void 0 : _branch$company.name))]), _c('data-list-item', {
      attrs: {
        "termXl": "1",
        "descXl": "11",
        "term": "Tel",
        "desc": branch === null || branch === void 0 ? void 0 : (_branch$company2 = branch.company) === null || _branch$company2 === void 0 ? void 0 : _branch$company2.phone,
        "showIfDesc": ""
      }
    }), _c('data-list-item', {
      attrs: {
        "termXl": "1",
        "descXl": "11",
        "term": "Fax",
        "desc": branch === null || branch === void 0 ? void 0 : (_branch$company3 = branch.company) === null || _branch$company3 === void 0 ? void 0 : _branch$company3.fax,
        "showIfDesc": ""
      }
    }), _c('div', {
      staticClass: "mt-10 mb-n6 mb-md-n10"
    }, [_c('v-row', {
      staticClass: "ma-n4"
    }, _vm._l((branch === null || branch === void 0 ? void 0 : (_branch$company4 = branch.company) === null || _branch$company4 === void 0 ? void 0 : _branch$company4.websites) || [], function (_ref) {
      var icon = _ref.icon,
        href = _ref.href;
      return _c('v-col', {
        key: href,
        staticClass: "pa-4",
        attrs: {
          "cols": "auto"
        }
      }, [_c('a', _vm._b({
        attrs: {
          "href": href,
          "target": "_blank"
        }
      }, 'a', {
        href
      }, false), [_c('v-row', {
        staticClass: "row--xx-small",
        attrs: {
          "align": "center"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('v-btn', {
        staticClass: "panels-basic__icon-btn",
        attrs: {
          "fab": "",
          "color": "secondary"
        }
      }, [_c('i', {
        class: 'icon ' + icon
      })])], 1), icon == 'icon-home' ? _c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('span', {
        staticClass: "font-size-14 grey--text"
      }, [_vm._v("homepage")])]) : _vm._e()], 1)], 1)]);
    }), 1)], 1)], 1)]), _c('v-expansion-panel-content', [branch !== null && branch !== void 0 && (_branch$company5 = branch.company) !== null && _branch$company5 !== void 0 && _branch$company5.desc ? _c('div', {
      staticClass: "mt-20 mt-md-34"
    }, [_c('div', {
      staticStyle: {
        "white-space": "pre-line"
      },
      domProps: {
        "innerHTML": _vm._s(branch === null || branch === void 0 ? void 0 : (_branch$company6 = branch.company) === null || _branch$company6 === void 0 ? void 0 : _branch$company6.desc)
      }
    }), _c('v-divider', {
      staticClass: "my-20 my-md-34"
    })], 1) : _vm._e(), _c('div', [_c('div', {
      staticClass: "font-spoqa font-size-16 font-weight-bold mb-10"
    }, [_vm._v("Contact")]), _c('v-row', {
      staticClass: "mx-n10 my-n4 mx-md-n24",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "px-10 py-4 px-md-24",
      attrs: {
        "cols": "12",
        "lg": "auto"
      }
    }, [_c('data-list-item', _vm._b({
      attrs: {
        "term": "Address",
        "desc": branch === null || branch === void 0 ? void 0 : (_branch$company7 = branch.company) === null || _branch$company7 === void 0 ? void 0 : _branch$company7.address,
        "showIfDesc": ""
      }
    }, 'data-list-item', _vm.liteItemProps, false))], 1), _c('v-col', {
      staticClass: "px-10 py-4 px-md-24",
      attrs: {
        "cols": "12",
        "lg": "auto"
      }
    }, [_c('data-list-item', _vm._b({
      attrs: {
        "term": "Email"
      },
      scopedSlots: _vm._u([{
        key: "descContents",
        fn: function () {
          var _branch$company8, _branch$company9;
          return [_c('a', {
            staticClass: "secondary--text word-wrap-break",
            attrs: {
              "href": 'mailto:' + (branch === null || branch === void 0 ? void 0 : (_branch$company8 = branch.company) === null || _branch$company8 === void 0 ? void 0 : _branch$company8.email)
            }
          }, [_vm._v(_vm._s(branch === null || branch === void 0 ? void 0 : (_branch$company9 = branch.company) === null || _branch$company9 === void 0 ? void 0 : _branch$company9.email))])];
        },
        proxy: true
      }], null, true)
    }, 'data-list-item', _vm.liteItemProps, false))], 1)], 1)], 1)])], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }