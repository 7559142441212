<template>
    <div :class="{ 'mt-36 mt-md-46 mt-lg-56': index != 0 }" ref="item">
        <div class="tit-wrap tit-wrap--hyphen mb-16 mb-md-30">
            <h2 class="tit tit--xs">{{ country.name }}</h2>
        </div>
        <v-expansion-panels flat class="panels-basic">
            <template v-for="branch in country.branches">
                <v-expansion-panel :key="branch._id">
                    <v-expansion-panel-header>
                        <div>
                            <div class="font-spoqa font-size-14 font-size-md-16 font-weight-medium secondary--text mb-10 mb-md-14">{{ branch?.company?.name }}</div>
                            <!-- <data-list-item term="Company Name" :desc="branch?.company?.name" showIfDesc /> -->
                            <data-list-item termXl="1" descXl="11" term="Tel" :desc="branch?.company?.phone" showIfDesc />
                            <data-list-item termXl="1" descXl="11" term="Fax" :desc="branch?.company?.fax" showIfDesc />

                            <div class="mt-10 mb-n6 mb-md-n10">
                                <v-row class="ma-n4">
                                    <v-col cols="auto" class="pa-4" v-for="{ icon, href } in branch?.company?.websites || []" :key="href">
                                        <a v-bind="{ href }" :href="href" target="_blank">
                                        <v-row align="center" class="row--xx-small">
                                            <v-col cols="auto">
                                                <v-btn fab color="secondary" class="panels-basic__icon-btn">
                                                    <i :class="'icon ' + icon" />
                                                </v-btn>
                                            </v-col>
                                            <v-col v-if="icon == 'icon-home'" cols="auto">
                                                <span class="font-size-14 grey--text">homepage</span>
                                            </v-col>
                                        </v-row>
                                        </a>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if="branch?.company?.desc" class="mt-20 mt-md-34">
                            <div v-html="branch?.company?.desc" style="white-space: pre-line" />
                            <v-divider class="my-20 my-md-34" />
                        </div>
                        <div>
                            <div class="font-spoqa font-size-16 font-weight-bold mb-10">Contact</div>
                            <v-row align="center" class="mx-n10 my-n4 mx-md-n24">
                                <v-col cols="12" lg="auto" class="px-10 py-4 px-md-24">
                                    <data-list-item term="Address" :desc="branch?.company?.address" showIfDesc v-bind="liteItemProps" />
                                </v-col>
                                <v-col cols="12" lg="auto" class="px-10 py-4 px-md-24">
                                    <data-list-item term="Email" v-bind="liteItemProps">
                                        <template #descContents>
                                            <a :href="'mailto:' + branch?.company?.email" class="secondary--text word-wrap-break">{{ branch?.company?.email }}</a>
                                        </template>
                                    </data-list-item>
                                </v-col>
                            </v-row>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </template>
        </v-expansion-panels>
    </div>
</template>

<script>
import DataListItem from "@/components/dumb/data-list-item.vue";

const dataItemProps = {
    termCols: "auto",
    termSm: "auto",
    termMd: "auto",
    termLg: "auto",
    termXl: "auto",
    descCols: "auto",
    descSm: "auto",
    descMd: "auto",
    descLg: "auto",
    descXl: "auto",
};
const liteItemProps = {
    // termCols: "auto",
    // termSm: "auto",
    // termMd: "auto",
    termLg: "auto",
    termXl: "auto",
    // descCols: "auto",
    // descSm: "auto",
    // descMd: "auto",
    descLg: "",
    descXl: "",
};
export default {
    components: {
        DataListItem,
    },
    props: {
        country: { type: Object, default: () => ({}) },
        index: { type: Number, default: 0 },
    },
    data: () => ({
        dataItemProps,
        liteItemProps,
    }),
    computed: {
        isOn() {
            return this.$route.query.flag == this.country?.flag;
        },
    },
    mounted() {
        this.scrollIntoView();
    },
    watch: {
        isOn() {
            this.scrollIntoView();
        },
    },
    methods: {
        scrollIntoView() {
            if (this.isOn) this.$nextTick(() => setTimeout(() => this.$refs.item?.scrollIntoView?.({ behavior: "smooth", block: "center" }), 500));
        },
    },
};
</script>

<style lang="scss" scoped>
.panels-basic {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-radius: 0 !important;
    ::v-deep {
        .v-expansion-panel {
            margin-top: 0 !important;
            &:not(:first-child) {
                border-top: 1px solid var(--border-color);
            }
        }
        .v-expansion-panel-header {
            padding: 20px 0;
            &__icon .v-icon {
                color: #2f2f2f !important;
            }
            &--active {
                .v-expansion-panel-header {
                    &__icon .v-icon {
                        color: var(--v-primary-base) !important;
                    }
                }
            }
        }
        .v-expansion-panel-content {
            background-color: #f8f8f8;
            &__wrap {
                padding: 20px;
            }
        }
    }
    &__icon-btn {
        width: 30px !important;
        height: 30px !important;
        .v-icon {
            font-size: 20px;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .panels-basic {
        ::v-deep {
            .v-expansion-panel-header {
                padding: 30px 0;
                &__icon .v-icon {
                    font-size: 2.8rem;
                }
            }
            .v-expansion-panel-content {
                &__wrap {
                    padding: 30px 60px;
                }
            }
        }
        &__icon-btn {
            width: 36px !important;
            height: 36px !important;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
