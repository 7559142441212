<template>
    <client-page disableScrollTop>
        <template v-slot:subHead>
            <sub-visual title="Global" tabActive="Network" src="/images/sub/sv-story-global-network.png"></sub-visual>
        </template>

        <v-container>
            <global-network-showcase class="mb-40 mb-md-60 mb-lg-80" />
            <global-network-list />
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import GlobalNetworkList from "@/components/client/global-network/global-network-list.vue";
import GlobalNetworkShowcase from "@/components/client/global-network/global-network-showcase.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        GlobalNetworkList,
        GlobalNetworkShowcase,
    },
    computed: {
        items() {
            return this.$refs["global-network-list"]?.items || [];
        },
    },
};
</script>
